@media (min-width: 768px) {
  #menu {
    min-height: 66px;
  }
  #menu .navbar-nav > li > a {
    padding-top: 21px;
    padding-bottom: 20px;
    padding-left: 13px;
    padding-right: 13px;
    font-size: 14px;
  }
  .slogan {
    padding: 94px 0;
  }

  .content-box,
  .content .box.sidebar {
    float: left;
  }
  .content h2.first,
  .content .box.sidebar h3 {
    font-size: 21px;
    padding: 0 0 15px 0;
  }
  .content {
    .box {
      margin-bottom: 30px;
    }
    .card {
      h3 {
        font-size: 18px !important;
      }
    }
  }
  .alkon-price {
    font-size: 18px;
  }
  .sidebar .fa-ul {
    padding-left: 15px;
  }
  .form-group-last {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  #menu {
    min-height: 80px;
  }
  #menu .navbar-nav > li > a {
    padding-top: 27px;
    padding-bottom: 28px;
    padding-left: 18px;
    padding-right: 18px;
    font-size: 16px;
  }
  h2 {
    font-size: 18px;
  }
  .address {
    padding: 30px;
    h2 {
      font-size: 30px;
    }
  }
  .slogan {
    padding: 65px 0;
    h1 {
      font-size: 60px;
    }
  }
  .content {
    margin-top: 50px;
    .box {
      padding: 30px;
    }
    .content-box {
      padding: 30px 30px 20px 30px;
    }
    h2.first {
      font-size: 24px;
    }
    .box.sidebar {
      padding: 0;
      .title {
        padding: 30px 30px 0 30px;
      }
      .text {
        padding: 30px 30px 20px 30px;
        min-height: 100px;
      }
      .price {
        padding: 15px 30px 20px 30px;
      }
      h3 {
        font-size: 24px;
        &:after {
          top:7px;
        }
      }
    }
    .box.card {
      .title {
        padding:30px 30px 15px 30px;
        h3 {
          font-size:16px !important;
        }
      }
    }
  }
  .content-box {
    float: left;
  }
  ul.album {
    h2.first {
      font-size: 24px;
    }
    li {
      a.thumbnail {
        padding: 30px;
      }
    }
  }
  .alkon-price {
    font-size: 21px;
  }
  .sidebar .fa-ul {
    padding-left: 0;
  }
  footer {
    margin-top: 50px;
  }
  #small-header:before {
    background-attachment: fixed;
  }
}

@media (min-width: 1200px) {
  #menu .navbar-nav > li > a {
    padding-top: 27px;
    padding-bottom: 28px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 18px;
  }
  .content .box.sidebar .text {
    min-height:100px;
  }
  .content {
    .box.card {
      .title {
        padding:30px 30px 15px 30px;
        h3 {
          font-size:20px !important;
        }
      }
    }
    .box.sidebar {
      h3 {
        &:after {
          top:10px;
        }
      }
    }
  }
  .slogan {
    padding:92px 0;
  }
}

@media (max-width: 991px) {
  body {
    padding-top:66px;
  }
}
@media (max-width: 767px) {
  body {
    padding-top: 50px;
  }
  #menu {
    min-height: 50px;
    margin-bottom: 0;
  }
  .navbar-default .navbar-toggle {
    border-color: $white;
    background: $white;
  }
  .navbar-default .navbar-toggle .icon-bar {
    background: $primary-color;
  }
  .navbar-default .navbar-toggle:hover,
  .navbar-default .navbar-toggle:focus {
    background: $secondary-color;
    border-color: $secondary-color;
  }
  .navbar-nav {
    margin: 7.5px -15px 0 -15px;
  }
  #menu .navbar-nav > li > a {
    border-top: 0px solid $primary-color;
    border-bottom: 1px solid $primary-color;
    color: $white;
    text-align: center;
  }

  #menu .navbar-nav > li > a:focus,
  #menu .navbar-nav > li > a:hover,
  #menu .navbar-nav > .active > a,
  #menu .navbar-nav > .active > a:hover,
  #menu .navbar-nav > .active > a:focus {
    background: transparent;
    color: $secondary-color;
    border-top: 0px solid $secondary-color;
    border-bottom: 1px solid $secondary-color;
  }
  #logo {
    width: auto;
    height: auto;
    top: 70px;
    img {
      max-width: 85%;
      display: block;
      margin: 0 auto;
    }
  }
  #small-header .img-logo {
    max-width: 100%;
    margin-top: 25px;
  }
  #small-header,
  #small-header:before {
    height: 150px;
  }
  .content-box,
  .content .box.sidebar {
    float: left;
  }
  .content {
    .box {
      margin-bottom: 30px;
    }
  }
  .address a.link {
    color:$primary-color;
    font-weight:bold;
    text-decoration:underline;
  }
  .header-svg {
    display: block;
    text-indent: -9999px;
    text-align: center;
    width: 140px;
    height: 140px;
    margin: 15px auto 0 auto;
    position: relative;
    z-index: 101;
  }
  .wesp {
    background: url('/images/svg/wesp.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 140px 140px;
    margin-top: 90px;
    &:hover,
    &:focus {
      background: url('/images/svg/wesp-hover.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 140px 140px;
    }
  }
  .rat {
    background: url('/images/svg/rat.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 140px 140px;
    margin-top: 90px;
    &:hover,
    &:focus {
      background: url('/images/svg/rat-hover.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 140px 140px;
    }
  }
  .gasmeting {
    background: url('/images/svg/gasmeting.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 140px 140px;
    &:hover,
    &:focus {
      background: url('/images/svg/gasmeting-hover.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 140px 140px;
    }
  }
  .youtube {
    background: url('/images/svg/youtube.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 140px 140px;
    &:hover,
    &:focus {
      background: url('/images/svg/youtube-hover.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 140px 140px;
    }
  }
  .address {
    text-align: center;
    h2 {
      font-size: 32px;
    }
  }
  .slogan {
    padding: 30px 0;
    h1 {
      span.first-line,
      span.second-line {
        padding: 0;
      }
    }
  }
  .content h2.first,
  .content .box.sidebar h3 {
    padding: 0 0 15px 0;
    font-size: 21px;
    line-height: 1.4;
  }
  .content .box.sidebar .text,
  .content .box.sidebar .price {
    padding: 15px 15px 5px 15px;
  }
  .sidebar .fa-ul {
    padding-left: 15px;
  }
  .content {
    .box.sidebar {
      h3 {
        &:after {
          top:12px;
        }
      }
    }
  }
  footer {
    margin-top: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .content .box.sidebar .title {
    height:70px;
    overflow:hidden;
  }
  .content .box.sidebar .text {
    height:80px;
    overflow:hidden;
  }
}