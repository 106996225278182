body {
  font-family: $font-sans-serif;
  font-weight: $font-weight-medium;
  font-size: 14px;
  color: $font-color;
  line-height:1.828571429;
  background: $bg-color;
  padding-top: 80px;
}

h2, h3 {
  font-size: 18px;
  color: $primary-color;
}
a {
  color:$primary-color;
  outline:0;
  &:focus,
  &:hover {
    outline:none !important;
  }
}

/* BUTTONS */
.btn-primary {
  background: $secondary-color;
  color: $white;
  position: relative;
  border-radius: 0;
  border: 0;
  padding: 10px 55px 10px 12px;
  text-transform: uppercase;
  font-weight: $font-weight-light;
  transition: all 0.5s ease;
  &:after {
    content: "\f054";
    font-family: FontAwesome;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 11px 0 12px 0;
    color: #fff;
    border-left: 1px solid #fff;
  }
  &:focus,
  &:hover {
    background: $primary-color;
  }
}

/* MENU */
#menu {
  min-height: 80px;
  background: #023d82;
  background: -moz-linear-gradient(top, #023d82 0%, #00489c 100%);
  background: -webkit-linear-gradient(top, #023d82 0%, #00489c 100%);
  background: linear-gradient(to bottom, #023d82 0%, #00489c 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#023d82', endColorstr='#00489c', GradientType=0);
  border-width: 0;
  text-transform: lowercase;
  -webkit-box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.6);
  box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.6);
  z-index: 999;
}

#menu .navbar-nav > li > a {
  border-top: 5px solid transparent;
  color: $white
}

#menu .navbar-nav > li > a:focus,
#menu .navbar-nav > li > a:hover,
#menu .navbar-nav > .active > a,
#menu .navbar-nav > .active > a:hover,
#menu .navbar-nav > .active > a:focus {
  background: transparent;
  border-top: 5px solid $secondary-color;
  color: $secondary-color;
}

/* HEADER */
#slider {
  position: relative;
  z-index: 0;
  .container {
    &:before {
      content: "";
      background: url('/images/overlay.png') no-repeat;
      background-position:top center;
      position: absolute;
      z-index: 5;
      left: 0;
      right: 0;
      overflow: hidden;
      margin: 0 auto;
      height: 450px;
      width: 100%;
    }
  }
}

#logo {
  position: absolute;
  width: 603px;
  top: 130px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10;
}

#small-header {
  height: 250px;
  width: 100%;
  background-attachment: fixed;
  position:relative;
  z-index:0;
  .img-logo {
    margin-top: 55px;
    z-index:10;
    position:relative;
  }
  &:before {
    content: "";
    background: url('/images/overlay.png') no-repeat;
    background-position:top center;
    position: absolute;
    z-index: 5;
    left: 0;
    right: 0;
    overflow: hidden;
    margin: 0 auto;
    height: 250px;
    width: 100%;
  }
}

.box {
  position: relative;
  background: $white;
  width: 100%;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(209, 209, 209, 1);
  -moz-box-shadow: 0px 0px 8px 0px rgba(209, 209, 209, 1);
  box-shadow: 0px 0px 8px 0px rgba(209, 209, 209, 1);
}

.box.blue {
  background: $primary-color;
}
.product-box {
  h2 {
    padding-bottom:0 !important;
  }
}
.content-box {
  float:left;
  h3 {
    margin-top: 30px;
  }
}

.address {
  padding: 15px;
  font-size: 16px;
  background: $white;
  h2 {
    font-weight: $font-weight-bold;
    color: $primary-color;
    text-transform: uppercase;
    font-size: 21px;
    padding: 0 0 20px 0;
    margin: 0;
  }
  a {
    color: $font-color;
  }
}

.slogan {
  background: $primary-color url('/images/bg-graat.jpg') repeat-x;
  background-size: 100%;
  background-position: top center;
  padding: 65px 0;
  color: $white;
  display: table;
  width: 100%;
  .title {
    display: table-cell;
    text-align: center;
  }
  h1 {
    font-weight: $font-weight-light;
    margin: 0;
    padding: 0;
    font-size: 40px;
    span.first-line,
    span.second-line {
      display: block;
    }
    span.first-line {
      padding-right: 120px;
    }
    span.second-line {
      padding-left: 120px;
    }
  }
}

/* CONTENT */
.content {
  margin-top: 30px;
  .box {
    padding: 15px;
  }
  h2.first, .box.sidebar h3 {
    color: $primary-color;
    background: #fff;
    padding: 0 0 30px 0;
    margin: 0px;
    font-size: 18px;
    position: relative;
    display: block;
    z-index: 10;
    &:after {
      content: "";
      width: 100%;
      height: 5px;
      top: 12px;
      right: 0;
      overflow: hidden;
      position: absolute;
      background: $bg-color;
      z-index: -1;
      -webkit-transition: background-color 1s ease;
      -moz-transition: background-color 1s ease;
      -o-transition: background-color 1s ease;
      transition: background-color 1s ease;
    }
    span {
      background: #fff;
      padding-right: 15px;
      display: inline-block;
    }
  }
  .box.sidebar {
    padding: 0;
    .title {
      padding: 15px 15px 0 15px;
    }
    .text,
    .price {
      padding: 15px;
    }
    h3 {
      color: $font-color;
      font-size: 16px;
      background:transparent;
    }
  }
}
.price {
  float:left;
  width:100%;
  background:$white;
  border-top:1px solid $bg-color;
}
.price-item {
  width:100%;
  margin-right:15px;
  font-weight:$font-weight-bold;
  span {
    color:$font-color;
    font-weight:$font-weight-light;
  }
}
.alkon-price {
  color:$primary-color;
}
.suggest-price {
  color:$font-color;
  font-weight:$font-weight-light;
  span {
    color:$font-color;
  }
}
.alkon-price {
  font-size:21px;
}
.header-svg {
  display: block;
  text-align: center;
  width:180px;
  height: 180px;
  margin:180px auto 0 auto;
  position:relative;
  z-index:101;
  span {
    position:absolute;
    z-index:102;
    bottom:20px;
    left:0;
    right:0;
    margin:0 auto;
  }
  &:focus,
  &:hover {
    span {
      color:$secondary-color;
    }
  }
}
.wesp {
  background: url('/images/svg/wesp.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 180px 180px;
  &:hover,
  &:focus {
    background: url('/images/svg/wesp-hover.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 180px 180px;
  }
}
.rat {
  background: url('/images/svg/rat.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 180px 180px;
  &:hover,
  &:focus {
    background: url('/images/svg/rat-hover.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 180px 180px;
  }
}
.gasmeting {
  background: url('/images/svg/gasmeting.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 180px 180px;
  &:hover,
  &:focus {
    background: url('/images/svg/gasmeting-hover.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 180px 180px;
  }
}
.youtube {
  background: url('/images/svg/youtube.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 180px 180px;
  &:hover,
  &:focus {
    background: url('/images/svg/youtube-hover.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 180px 180px;
  }
}

/* SIDE BUTTONS */
.contact-buttons {
  position: fixed;
  right: 0;
  top: 40%;
  z-index: 100;
  .mail,
  .phone {
    display: block;
    text-indent: -9999px;
    text-align: center;
    width: 75px;
    height: 80px;
    margin-top: 5px;
    -webkit-transition: background-color 0.7s ease-out;
    -moz-transition: background-color 0.7s ease-out;
    -o-transition: background-color 0.7s ease-out;
    transition: background-color 0.7s ease-out;
  }
  .phone {
    background: $primary-color url('/images/svg/phone.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 43px 52px;
    &:hover,
    &:focus {
      background: $secondary-color url('/images/svg/phone.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 43px 52px;
    }
  }
  .mail {
    background: $primary-color url('/images/svg/mail.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 43px 52px;
    &:hover,
    &:focus {
      background: $secondary-color url('/images/svg/mail.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 43px 52px;
    }
  }
}

/* INTRO BLOK */
section.content-main {
  margin-top: -50px !important;
}

#home-intro {
  .box {
    margin-top: -50px !important;
  }
}
.card {
  margin-bottom:30px;
  float:left;
}
.productLink {
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
  &:focus,
  &:hover {
    text-decoration:none;
    .box, .box.sidebar h3 {
      color:$primary-color;
    }
    .box.sidebar h3:after {
      background:$primary-color;
    }
  }
}
.box.sidebar {
  .btn-primary {
    width:100%;
  }
}
/* CONTACT */
#contact {
  h3.form {
    margin-top:30px;
    margin-bottom:15px;
  }
  .form-control {
    height:42px;
    padding:10px 12px;
    background:$bg-color;
    border-color:$bg-color;
    border-radius:0;
    box-shadow:none;
  }
  textarea.form-control {
    padding:12px 12px;
    height:auto;
  }
  .form-control::-webkit-input-placeholder { color: $secondary-color; }
  .form-control:-moz-placeholder { color: $secondary-color; }
  .form-control::-moz-placeholder { color: $secondary-color; }
  .form-control:-ms-input-placeholder { color: $secondary-color; }

  textarea:focus,
  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="datetime"]:focus,
  input[type="datetime-local"]:focus,
  input[type="date"]:focus,
  input[type="month"]:focus,
  input[type="time"]:focus,
  input[type="week"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="url"]:focus,
  input[type="search"]:focus,
  input[type="tel"]:focus,
  input[type="color"]:focus,
  .uneditable-input:focus {
    border-color: rgba(0, 72, 156, 0.8);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 72, 156, 0.1);
    outline: 0 none;
  }
}
.fa-contactgegevens {
  li:first-child {
    margin-bottom:25px;
  }
  li:last-child {
    margin-top:15px;
    padding-top:10px;
  }
  i {
    font-size:30px;
    color:$bg-color;
  }
}

/* ALBUMS */
ul.album {
  margin:0;
  padding:0;
  list-style:none;
  h2.first {
    color: $primary-color;
    background: #fff;
    padding: 0 0 30px 0;
    margin: 0px;
    font-size: 18px;
    position: relative;
    display: block;
    z-index: 10;
    &:after {
      content: "";
      width: 100%;
      height: 5px;
      top: 12px;
      right: 0;
      overflow: hidden;
      position: absolute;
      background: $bg-color;
      z-index: -1;
    }
    span {
      background: #fff;
      padding-right: 15px;
      display: inline-block;
    }
  }
  li {
    a.thumbnail {
      box-shadow:0px 0px 8px 0px #d1d1d1;
      border-radius:0;
      padding:15px;
      margin-bottom:30px;
    }
  }
}
ul.fotoItems {
  margin:0;
  padding:0;
  list-style:none;
  float:left;
  li {
    float:left;
    margin:10px;
    padding:4px;
    box-shadow:0px 0px 8px 0px #d1d1d1;
    border:1px solid $white;
    &:hover,
    &:focus {
      border:1px solid #eee;
    }
  }
}
.back {
  background: $secondary-color;
  color: $white;
  position: relative;
  border-radius: 0;
  border: 0;
  padding: 9px 12px 6px 55px;
  text-transform: uppercase;
  font-weight: $font-weight-light;
  float:left;
  margin-top:30px;
  &:after {
    content: "\f053";
    font-family: FontAwesome;
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 10px 0 12px 0;
    color: #fff;
    border-right: 1px solid #fff;
  }
  &:focus,
  &:hover {
    background: $primary-color;
    color:$white;
    text-decoration: none;
  }
}
/* FOOTER */
footer {
  background: $font-color;
  margin-top: 30px;
  color: $white;
  padding: 30px 0;
  text-align: center;
  a {
    color:$white;
    &:hover,
    &:focus {
      text-decoration:underline;
      color:$white;
    }
  }
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
}