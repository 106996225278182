// COLORS
$primary-color:                 #00489c;
$secondary-color:               #eeba00;

$bg-color:                      #f5f5f5;

$white:                         #ffffff;
$black:                         #000000;

$font-color:                    #3c3c3c;

$font-weight-light:             300;
$font-weight-medium:            400;
$font-weight-bold:              700;

$font-sans-serif:               'Raleway', sans-serif;